<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="year" label="Tahun">
            <a-input-number v-model:value="formState.year" placeholder="Masukkan Tahun" />
          </a-form-item>
          <a-form-item name="parent_id" label="Parent KPI">
            <a-select
              placeholder="Pilih Parent KPI"
              v-model:value="formState.parent_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.kpiDivisi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="name" label="Indikator Kinerja">
            <a-input v-model:value="formState.name" placeholder="Masukkan Indikator Kinerja" />
          </a-form-item>
          <a-form-item name="satuan_kpi_id" label="Satuan">
            <a-select
              placeholder="Pilih Satuan"
              v-model:value="formState.satuan_kpi_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.satuan" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="jenis_variable_kpi_id" label="Jenis Variable KPI">
            <a-select
              placeholder="Pilih Jenis Variable KPI"
              v-model:value="formState.jenis_variable_kpi_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.jenisVariableKpi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="bobot" label="Bobot">
            <a-input-number v-model:value="formState.bobot" placeholder="Masukkan Bobot" />
          </a-form-item>
          <a-form-item name="polarisasi" label="Polarisasi">
            <a-select
              placeholder="Pilih Polarisasi"
              v-model:value="formState.polarisasi"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.polarisasi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            name="target_nominal"
            label="Target Dalam 1 Tahun"
            v-if="formState.jenis_variable_kpi_id == 1 && formState.polarisasi != 'Rentang'"
          >
            <a-input-number v-model:value="formState.target_nominal" placeholder="Masukkan Target Nominal" />
          </a-form-item>
          <a-form-item
            name="target_waktu"
            label="Target Dalam 1 Tahun"
            v-if="formState.jenis_variable_kpi_id == 2 && formState.polarisasi != 'Rentang'"
          >
            <a-month-picker
              v-model:value="formState.target_waktu"
              placeholder="Pilih Bulan"
              format="MM-YYYY"
              valueFormat="YYYY-MM"
            />
          </a-form-item>
          <a-form-item
            name="target_nominal"
            label="Target Min Dalam 1 Tahun"
            v-if="formState.jenis_variable_kpi_id == 1 && formState.polarisasi == 'Rentang'"
          >
            <a-input-number v-model:value="formState.target_nominal" placeholder="Masukkan Target Min Nominal" />
          </a-form-item>
          <a-form-item
            name="target_nominal_max"
            label="Target Max Dalam 1 Tahun"
            v-if="formState.jenis_variable_kpi_id == 1 && formState.polarisasi == 'Rentang'"
          >
            <a-input-number v-model:value="formState.target_nominal_max" placeholder="Masukkan Target Max Nominal" />
          </a-form-item>
          <a-form-item name="variable_kpi_divisi_id" label="Variable KPI">
            <a-row>
              <a-col :md="20" :sm="20" :xs="20">
                <a-select
                  placeholder="Pilih Variable KPI"
                  v-model:value="formState.variable_kpi_divisi_id"
                  show-search
                  :filter-option="filterSelect"
                  mode="multiple"
                >
                  <a-select-option v-for="option in options.variableKpiDivisi" :key="option.value">
                    {{ option.text }} [{{ option.value }}]
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :md="4" :sm="4" :xs="4">
                <a-button
                  class="sDash_form-action__btn"
                  type="primary"
                  size="large"
                  style="margin-left: 10px;"
                  @click="showModal"
                >
                  <sdFeatherIcons type="plus" size="14" /> Tambah Variable
                </a-button>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item name="formula" label="Formula">
            <a-input v-model:value="formState.formula" placeholder="Masukkan Formula" />
          </a-form-item>
          <a-form-item name="formula_description" label="Deskripsi Formula">
            <a-input v-model:value="formState.formula_description" placeholder="Masukkan Deskripsi Formula" />
          </a-form-item>
          <a-form-item
            name="is_akumulasi"
            label="Akumulasi"
            extra="Akumulasi : Data realisasi akan dijumlahkan tiap bulan "
          >
            <a-select
              placeholder="Pilih Akumulasi"
              v-model:value="formState.is_akumulasi"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.akumulasi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            name="is_breakdown_target"
            label="Breakdown Target"
            extra="Breakdown Target : Jumlah isian target menyesuaikan variabel"
          >
            <a-select
              placeholder="Pilih Breakdown Target"
              v-model:value="formState.is_breakdown_target"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.breakdownTarget" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link
                  :to="
                    '/kpi/kpi-divisi/' + $route.params.divisi_id + '/' + $route.params.month + '/' + $route.params.year
                  "
                >
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>

    <a-modal v-model:visible="modalState.visible" title="Tambah Variable">
      <a-form
        layout="horizontal"
        :model="formModalState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        :rules="modalRules"
        id="addVarForm"
        @finish="addVariable"
      >
        <a-form-item name="name" label="KPI">
          <a-input v-model:value="formModalState.name" placeholder="Masukkan KPI" />
        </a-form-item>
        <a-form-item name="description" label="Deskripsi">
          <a-input v-model:value="formModalState.description" placeholder="Masukkan Deskripsi" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button key="back" @click="handleCancel">Batal</a-button>
        <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="addVarForm">
          {{ modalState.isLoading ? 'Harap Menunggu...' : mode }}
        </a-button>
      </template>
    </a-modal>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';

const KpiDivisiForm = {
  name: 'KpiDivisiForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();

    const modalState = reactive({
      isLoading: false,
      visible: false,
    });

    const formModalState = reactive({
      name: '',
      description: '',
    });

    const formState = reactive({
      parent_id: undefined,
      satuan_kpi_id: undefined,
      jenis_variable_kpi_id: undefined,
      name: '',
      bobot: null,
      target_nominal: null,
      target_nominal_max: null,
      target_waktu: '',
      year: dateObj.getUTCFullYear(),
      polarisasi: undefined,
      formula: '',
      formula_description: '',
      variable_kpi_divisi_id: [],
      is_akumulasi: undefined,
      is_breakdown_target: undefined,
    });

    const options = reactive({
      kpiDivisi: [],
      satuan: [],
      jenisVariableKpi: [],
      polarisasi: [
        {
          value: 'Positif',
          text: 'Positif',
        },
        {
          value: 'Negatif',
          text: 'Negatif',
        },
      ],
      variableKpiDivisi: [],
      akumulasi: [
        {
          value: false,
          text: 'Tidak',
        },
        {
          value: true,
          text: 'Ya',
        },
      ],
      breakdownTarget: [
        {
          value: false,
          text: 'Tidak',
        },
        {
          value: true,
          text: 'Ya',
        },
      ],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = values => {
      if (values.target_waktu) {
        let my = values.target_waktu.split('-');
        values['target_waktu'] = my[0] + '-' + my[1] + '-' + '01';
      }

      values.divisi_id = params.divisi_id;

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'kpi-divisi',
          data: values,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'kpi-divisi',
          data: values,
        });
      }
    };

    const rules = {
      name: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const modalRules = {
      name: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', 'options-kpi-divisi').then(() => {
        options.kpiDivisi = crud.value;

        if (props.mode == 'Tambah') {
          formState.parent_id = parseInt(params.id);
        }
      });

      dispatch('axiosCrudGetData', 'options-satuan-kpi').then(() => {
        options.satuan = crud.value;
      });

      dispatch('axiosCrudGetData', 'options-jenis-variable-kpi').then(() => {
        options.jenisVariableKpi = crud.value;
      });

      if (props.mode == 'Ubah') {
        dispatch('axiosCrudGetData', 'options-variable-kpi-divisi-unused-owned/' + params.id).then(() => {
          options.variableKpiDivisi = crud.value;
        });

        const data = {
          url: 'kpi-divisi',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });
        });
      } else {
        dispatch('axiosCrudGetData', 'options-variable-kpi-divisi-unused').then(() => {
          options.variableKpiDivisi = crud.value;
        });
      }
    });

    const showModal = () => {
      modalState.visible = true;
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const addVariable = () => {
      modalState.isLoading = true;

      DataService.post('variable-kpi-divisi', formModalState).then(() => {
        modalState.visible = false;
        modalState.isLoading = false;
        formModalState.name = '';
        formModalState.description = '';

        DataService.get('options-variable-kpi-divisi-unused').then(response => {
          options.variableKpiDivisi = response.data;
        });
      });
    };

    watch(
      () => formState.polarisasi,
      val => {
        if (val == 'Rentang') {
          formState.jenis_variable_kpi_id = 1;
          formState.is_akumulasi = false;
          formState.is_breakdown_target = false;
        }
      },
    );

    watch(
      () => formState.jenis_variable_kpi_id,
      val => {
        if (val == 1) {
          options.polarisasi = [
            {
              value: 'Positif',
              text: 'Positif',
            },
            {
              value: 'Negatif',
              text: 'Negatif',
            },
            {
              value: 'Rentang',
              text: 'Rentang',
            },
          ];

          if (formState.polarisasi == 'Rentang') {
            formState.is_akumulasi = false;
            formState.is_breakdown_target = false;
          }
        } else {
          if (formState.polarisasi == 'Rentang') {
            formState.polarisasi = undefined;
          }

          options.polarisasi = [
            {
              value: 'Positif',
              text: 'Positif',
            },
            {
              value: 'Negatif',
              text: 'Negatif',
            },
          ];
        }
      },
    );

    watch(
      () => formState.is_akumulasi,
      val => {
        if (val == true) {
          if (formState.polarisasi == 'Rentang') {
            formState.polarisasi = undefined;
          }
        }
      },
    );

    watch(
      () => formState.is_breakdown,
      val => {
        if (val == true) {
          if (formState.polarisasi == 'Rentang') {
            formState.polarisasi = undefined;
          }
        }
      },
    );

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      modalRules,
      filterSelect,
      options,
      modalState,
      showModal,
      handleCancel,
      addVariable,
      formModalState,
    };
  },
};

export default KpiDivisiForm;
</script>
